import * as React from "react"

import Layout from "../../components/layout.de"
import Nav from "../../components/Nav.de"

// markup
const IndexPageDe = () => {
  return (
    <Layout>
      <Nav />
      <main className="p-4 mx-auto my-16 max-w-screen-3xl">
      </main>
    </Layout>
  )
}

export default IndexPageDe
